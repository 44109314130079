import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import liveOrdersReducer from './slices/liveOrders';
import overviewReducer from './slices/overview';
import revenueReducer from './slices/revenue';
import analyticsReducer from './slices/analytics';
import userReducer from './slices/user';
import customerLifetimeReducer from './slices/customerLifetime';
import trafficSourceReducer from './slices/trafficSource';
import purchaseReducer from './slices/purchase';
import productInsightReducer from './slices/productInsight';
import discountCodeReducer from './slices/discountCode';
import notificationReducer from './slices/notification';
import bestSellerReducer from './slices/bestSeller';
import adsPerformanceReducer from './slices/adsPerformance';
import adsOrdersReducer from './slices/adsOrders';
import adsOrderDetailsReducer from './slices/adsOrderDetails';
import adsCreativePerformanceReducer from './slices/adsCreativePerformance';
import churnReducer from './slices/churn';
import attributionReducer from './slices/attribution';
import onboardingReducer from './slices/onboarding';
import navbarReducer from './slices/navbar';
import subscriptionReducer from './slices/subscription';
import utmValidatorReducer from './slices/utmValidator';
import allSourcesReducer from './slices/allSources';
import dashboardConfigReducer from './slices/dashboardConfig';
import benchmarkingReducer from './slices/benchmarking';
import PpcAdsConfigReducer from './slices/ppcAdsConfig';
import landingPage from './slices/landingPage';
import productDetailPage from './slices/productDetailPage';
import convRateSource from './slices/convRateSource';
import profitAndLoss from './slices/profitAndLoss';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  overview: overviewReducer,
  liveOrders: liveOrdersReducer,
  revenue: revenueReducer,
  analytics: analyticsReducer,
  customerLifetime: customerLifetimeReducer,
  trafficSource: trafficSourceReducer,
  purchase: purchaseReducer,
  productInsight: productInsightReducer,
  discountCode: discountCodeReducer,
  notifications: notificationReducer,
  bestSeller: bestSellerReducer,
  adsPerformance: adsPerformanceReducer,
  adsOrders: adsOrdersReducer,
  adsOrderDetails: adsOrderDetailsReducer,
  adsCreativePerformance: adsCreativePerformanceReducer,
  churn: churnReducer,
  attribution: attributionReducer,
  allSources: allSourcesReducer,
  onboarding: onboardingReducer,
  navbar: navbarReducer,
  subscription: subscriptionReducer,
  utmValidator: utmValidatorReducer,
  dashboardConfig: dashboardConfigReducer,
  benchmarking: benchmarkingReducer,
  ppcAdsConfig: PpcAdsConfigReducer,
  landingPage: landingPage,
  productDetailPage: productDetailPage,
  convRateSource: convRateSource,
  profitAndLoss: profitAndLoss,
});

export { rootPersistConfig, rootReducer };
