import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '@/layouts/main';
import DashboardLayout from '@/layouts/dashboard';
import LogoOnlyLayout from '@/layouts/LogoOnlyLayout';
// guards
import GuestGuard from '@/guards/GuestGuard';
import AuthGuard from '@/guards/AuthGuard';
// import RoleBasedGuard from '@/guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '@/components/LoadingScreen';
import SubscriptionCheck from '@/pages/subscription/SubscriptionCheck';
import AttributionAllSources from '@/pages/dashboard/attribution/all-sources';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginAuth0 />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'shopify/callback', element: <ConnectSuccess /> },
        {
          path: 'oauth2callback',
          element: <GoogleOAuthCallback />,
          children: [{ path: '*', element: <GoogleOAuthCallback /> }],
        },
        { path: 'forcelogin', element: <ForceLogin /> },
      ],
    },

    // Connect
    {
      path: 'connect',
      element: <Connect />,
    },
    {
      path: 'connect_success',
      element: <ConnectSuccess />,
    },
    {
      path: 'install',
      element: <Install />,
    },

    // Consent
    {
      path: 'consent',
      element: (
        <AuthGuard>
          <Consent />
          <SubscriptionCheck />
        </AuthGuard>
      ),
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
          <SubscriptionCheck />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'live-orders', element: <LiveOrders /> },
        { path: 'custom', element: <DailyOverview /> },

        { path: 'all', element: <AttributionAllSources /> },
        /* Old Attribution All Source Component */
        /* TODO: we need to clean this up once AttributionAllSources is on prod */
        { path: 'old-all', element: <AllSource /> },
        { path: 'paid-ads', element: <PaidAds /> },
        // { path: 'ad-creatives', element: <AdCreatives /> },
        { path: 'kpi', element: <KPIBuilder /> },
        { path: 'benchmarking', element: <Benchmarking /> },
        { path: 'marketing-efficiency', element: <MarketingEfficiency /> },
        { path: 'profitAndLoss', element: <ProfitLossReport /> },
        { path: 'funnel', element: <Funnel /> },
        { path: 'orders/:id', element: <OrderDetails /> },

        { path: 'churn-analysis', element: <CustomerCounts /> },
        { path: 'ltv-trends', element: <LTVTrends /> },
        { path: 'full-analysis', element: <FullAnalysis /> },

        { path: 'cohort-analysis', element: <CohortAnalysis /> },
        { path: 'lost-customers', element: <LostCustomers /> },
        { path: 'monthly-trends', element: <MonthlyTrends /> },

        { path: 'new-customers', element: <Acquisition /> },
        { path: 'returning-customers', element: <Retention /> },
        { path: 'discount-codes', element: <DiscountCodes /> },

        { path: 'top-sellers', element: <TopSellers /> },
        { path: 'repurchase-rates', element: <RepurchaseRates /> },

        { path: 'checklist', element: <Checklist /> },
        { path: 'integrations', element: <Integrations /> },
        { path: 'ads-performance', element: <AdsPerformance /> },
        {
          path: 'ads-creative-performance',
          element: <AdsCreativePerformance />,
        },

        { path: 'utm-builder', element: <UTMBuilderV2 /> },
        { path: 'utm-validator', element: <UTMValidator /> },
        { path: 'store-settings', element: <StoreSettings /> },
        // Redirect from old url (kpi-settings) to the new one
        {
          path: 'kpi-settings',
          element: <Navigate to="/dashboard/store-settings" replace />,
        },
        { path: 'landing-page', element: <LandingPage /> },
        { path: 'product-detail-page', element: <ProductDetailPage /> },
        { path: 'conv-rate-source', element: <ConvRateSource /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('@/pages/auth/Login')));
const LoginAuth0 = Loadable(lazy(() => import('@/pages/auth/LoginAuth0')));
const Register = Loadable(lazy(() => import('@/pages/auth/Register')));
const ResetPassword = Loadable(
  lazy(() => import('@/pages/auth/ResetPassword'))
);
const VerifyCode = Loadable(lazy(() => import('@/pages/auth/VerifyCode')));
const ForceLogin = Loadable(lazy(() => import('@/pages/auth/ForceLogin')));
// Dashboard

const DailyOverview = Loadable(
  lazy(() => import('@/pages/dashboard/DailyOverview'))
);

const LiveOrders = Loadable(lazy(() => import('@/pages/dashboard/LiveOrders')));

// const NewAllSources = Loadable(
//   lazy(() => import('../pages/dashboard/attribution/all-sources'))
// );

const AllSource = Loadable(
  lazy(() => import('../pages/dashboard/attribution/all'))
);
const PaidAds = Loadable(
  lazy(() => import('../pages/dashboard/attribution/paid-ads'))
);

const LandingPage = Loadable(
  lazy(() => import('@/pages/webConversion/landingPage'))
);

const ProductDetailPage = Loadable(
  lazy(() => import('@/pages/webConversion/productDetailPage'))
);

const ConvRateSource = Loadable(
  lazy(() => import('@/pages/webConversion/convRateSource'))
);

// const AdCreatives = Loadable(
//   lazy(() => import('../pages/dashboard/attribution/ad-creatives'))
// );
const Funnel = Loadable(
  lazy(() => import('../pages/dashboard/attribution/funnel'))
);
const MarketingEfficiency = Loadable(
  lazy(() => import('../pages/dashboard/attribution/marketing-efficiency'))
);
const ProfitLossReport = Loadable(
  lazy(() => import('../pages/dashboard/attribution/profitLossReport'))
);

const KPIBuilder = Loadable(lazy(() => import('../pages/dashboard/kpi')));

const CustomerCounts = Loadable(
  lazy(() => import('@/pages/dashboard/CustomerCounts'))
);
const LTVTrends = Loadable(lazy(() => import('@/pages/dashboard/LTVTrends')));
const FullAnalysis = Loadable(
  lazy(() => import('@/pages/dashboard/FullAnalysis'))
);
const Acquisition = Loadable(
  lazy(() => import('@/pages/dashboard/Acquisition'))
);
const Retention = Loadable(lazy(() => import('@/pages/dashboard/Retention')));
const DiscountCodes = Loadable(
  lazy(() => import('@/pages/dashboard/DiscountCodes'))
);
const TopSellers = Loadable(lazy(() => import('@/pages/dashboard/TopSellers')));
const RepurchaseRates = Loadable(
  lazy(() => import('@/pages/dashboard/RepurchaseRates'))
);

const Checklist = Loadable(lazy(() => import('@/pages/dashboard/Checklist')));

const Integrations = Loadable(
  lazy(() => import('@/pages/dashboard/Integrations'))
);

const GoogleOAuthCallback = Loadable(
  lazy(() => import('@/pages/auth/GoogleOAuthCallback'))
);
const AdsPerformance = Loadable(
  lazy(() => import('@/pages/dashboard/AdsPerformance'))
);
const OrderDetails = Loadable(
  lazy(() => import('@/pages/dashboard/AdsOrderDetails'))
);
const AdsCreativePerformance = Loadable(
  lazy(() => import('@/pages/dashboard/AdsCreativePerformance'))
);

const CohortAnalysis = Loadable(
  lazy(() => import('@/pages/dashboard/ChurnCohortAnalysis'))
);
const LostCustomers = Loadable(
  lazy(() => import('@/pages/dashboard/ChurnActiveCustomers'))
);

const MonthlyTrends = Loadable(
  lazy(() => import('@/pages/dashboard/ChurnMonthlyTrends'))
);
const UTMBuilder = Loadable(lazy(() => import('@/pages/dashboard/UTMBuilder')));
const UTMBuilderV2 = Loadable(
  lazy(() => import('@/pages/dashboard/UTMBuilderV2'))
);
const UTMValidator = Loadable(
  lazy(() => import('@/pages/dashboard/utm/UTMValidator'))
);
const StoreSettings = Loadable(
  lazy(() => import('src/pages/dashboard/storeSettings'))
);

// Main
// const HomePage = Loadable(lazy(() => import("@/pages/Home")));
const ComingSoon = Loadable(lazy(() => import('@/pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('@/pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('@/pages/Page500')));
const NotFound = Loadable(lazy(() => import('@/pages/Page404')));

//Connect
const Connect = Loadable(lazy(() => import('@/pages/connect/Connect')));
const ConnectSuccess = Loadable(
  lazy(() => import('@/pages/connect/ConnectSuccess'))
);

//Consent
const Consent = Loadable(lazy(() => import('@/pages/Consent')));

// Install
const Install = Loadable(lazy(() => import('@/pages/Install')));

// Benchmarking
const Benchmarking = Loadable(
  lazy(() => import('@/pages/dashboard/Benchmarking'))
);
